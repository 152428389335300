.footer {
  margin-top: 20px;
  padding-bottom: 80px;
  font-size: 14px;
  line-height: 25px;
  background-color: #fff;
  margin-left: 35px;
}
@media only screen and (max-width: 420px) {
  .footer {
    margin-left: 5px;
  }
}
.esim-footer {
  margin-top: 25px;
}
.esim-footer .getting-connected {
  padding: 20px;
  font-size: 14px;
  line-height: 25px;
}
.esim-footer .help-support {
  padding: 20px;
  font-size: 14px;
  line-height: 25px;
  background-color: #E4F3FF;
}
