.tso-invitation-border {
  border-radius: 8px 8px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border: 1px solid #E0E0E0;
  display: block;
}
.tso-invitation-content {
  margin: auto 0;
  padding: 10px 10px 12px;
  background: none repeat scroll 0 0 #212121;
  min-height: 15px;
  opacity: 0.7;
  color: #f8f8f8 !important;
  font-weight: bold;
  z-index: 1000;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.tso-invitation-content .tso-invitation-caption {
  font-size: 14px;
}
.bottom-right-container {
  bottom: 0;
  position: fixed;
  right: 10px;
  z-index: 1000;
}
a.tso-invitation-link,
a.tso-invitation-link:hover,
a.tso-invitation-link:visited,
a.tso-invitation-link:active {
  color: #f8f8f8 !important;
  padding: 0 5px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
a.tso-invitation-close,
a.tso-invitation-close:hover,
a.tso-invitation-close:visited,
a.tso-invitation-close:active {
  color: #f8f8f8 !important;
  padding: 0 0 0 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
