.fullpage-error-container {
  margin-top: 26px !important;
  width: 100%;
}
.fullpage-error-container .chat-wrapper .need-help {
  margin-top: 72px;
  color: #282828;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 17.5px;
  margin-bottom: 0;
}
@media only screen and (min-width: 62rem) {
  .fullpage-error-container {
    margin-top: 65px !important;
    width: 80%;
    margin-left: 8% !important;
  }
}
.fullpage-error-img {
  max-width: 52px;
  max-height: 52px;
}
.fullpage-error-message {
  margin-bottom: 1rem;
}
.esim-error-flow {
  display: flex;
  flex-flow: row;
}
@media only screen and (max-width: 420px) {
  .esim-error-flow {
    flex-flow: column-reverse;
  }
}
@media only screen and (max-width: 767px) {
  .esim-error-flow {
    flex-flow: column-reverse;
  }
}
@media only screen and (min-width: 768px) {
  .esim-error-message-1 {
    margin-top: 3rem !important;
    margin-bottom: 4rem !important;
  }
}
@media only screen and (min-width: 768px) {
  .startover-button {
    width: 70% !important;
  }
}
.esim-error-img {
  height: 178px;
  width: 259px;
  margin-left: 50%;
}
@media only screen and (max-width: 420px) {
  .esim-error-img {
    height: 100px;
    width: 146px;
    margin-top: 32px;
    margin-left: 0%;
  }
}
@media only screen and (max-width: 767px) {
  .esim-error-img {
    height: 100px;
    width: 146px;
    margin-top: 32px;
    margin-left: 0%;
  }
}
@media screen and (min-width: 768px) and (max-width: 780px) {
  .esim-error-img {
    height: 100px;
    width: 146px;
    margin-top: 32px;
    margin-left: -20%;
  }
}
.esim-error-message-div {
  width: 90%;
}
.esim-chat-wrapper .need-help {
  margin-top: 2rem;
}
@media only screen and (min-width: 768px) {
  .esim-chat-wrapper .need-help {
    margin-top: 6rem !important;
  }
}
