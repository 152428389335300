.esim-wrapper .ppa-message {
  max-width: 660px !important;
  margin-left: 100px;
  border: 1px solid #0064D2;
  margin-bottom: 20px;
}
.esim-wrapper .ppa-message .icon-wrapper {
  background-color: #EBF6FF;
}
.esim-wrapper .ppa-message .message-box {
  background-color: #EBF6FF;
  color: #0064D2;
  padding-top: 10px !important;
}
.ppa-full-page-message {
  text-align: center;
}
.ppa-full-page-message .btn {
  margin-top: 20px;
}
.ppa-full-page-message .message-box {
  margin-bottom: 15px;
  font-size: 14px;
}
.ppa-full-page-message .button-box .error-button {
  float: none !important;
}
.ppa-message {
  display: flex;
  margin: 0px;
  background: #f8f8f8 !important;
  overflow: hidden;
  word-wrap: break-word;
}
.ppa-message.warning {
  border: 2px solid #B32034;
  box-shadow: inset 50px 0 rgba(171, 0, 76, 0.1);
}
.ppa-message.warning .td-icon-sm {
  color: #B32034 !important;
}
.ppa-message.info {
  border: 1px solid #2C74D3;
  box-shadow: inset 50px 0 rgba(0, 88, 171, 0.1);
  background-color: #F4FAFF;
}
.ppa-message.info .td-icon-sm {
  color: #0099d2 !important;
}
.ppa-message.info .icon-wrapper {
  padding-top: 10px;
  padding-left: 5px;
}
.ppa-message .message-box {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 25px;
}
.ppa-message .icon-wrapper {
  padding-top: 5px;
}
.ppa-message .td-icon-sm {
  position: relative;
  padding-left: 5px;
  font-size: 25px !important;
}
