.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin: 0 auto;
  -webkit-animation: play 3s steps(45) infinite;
  -moz-animation: play 3s steps(45) infinite;
  -ms-animation: play 3s steps(45) infinite;
  -o-animation: play 3s steps(45) infinite;
  animation: play 3s steps(45) infinite;
}
@-webkit-keyframes play {
  from {
    background-position: 0px;
  }
  to {
    background-position: -2160px;
  }
}
@-moz-keyframes play {
  from {
    background-position: 0px;
  }
  to {
    background-position: -2160px;
  }
}
@-ms-keyframes play {
  from {
    background-position: 0px;
  }
  to {
    background-position: -2160px;
  }
}
@-o-keyframes play {
  from {
    background-position: 0px;
  }
  to {
    background-position: -2160px;
  }
}
@keyframes play {
  from {
    background-position: 0px;
  }
  to {
    background-position: -2160px;
  }
}
.base-green .loading-spinner {
  background-image: url("../../assets/img/telstra/loader/loader-green.png");
}
.base-orange .loading-spinner {
  background-image: url("../../assets/img/telstra/loader/loader-orange.png");
}
.base-blue .loading-spinner {
  background-image: url("../../assets/img/telstra/loader/loader-blue.png");
}
.base-magenta .loading-spinner {
  background-image: url("../../assets/img/telstra/loader/loader-magenta.png");
}
.center-loading .loading-spinner {
  margin: 0 auto;
}
.div-width {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.div-width .spinner_message {
  width: 250px;
  margin: auto;
  text-align: center;
  margin-top: 130px;
}
.div-width.hidden {
  display: none;
}
.payment_spinner .loader {
  transform: translate(-20px, -4px);
}
