.modal-lg {
  width: 50% !important;
}
@media only screen and (max-width: 420px) {
  .modal-lg {
    width: 90% !important;
  }
}
.close {
  background-color: Transparent !important;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding-right: 40px !important;
  height: auto !important;
}
.close:after {
  color: #1964C8 !important;
  font-size: 26px !important;
  padding-top: 25px;
}
@media only screen and (max-width: 420px) {
  .close {
    padding-right: 60px !important;
  }
}
.modal-title.grey.sub-heading {
  margin-left: 60px;
  margin-top: 25px;
  color: #333333;
  font-size: 26px;
  line-height: 28px;
  text-align: center;
  padding-bottom: 5px !important;
  display: block !important;
  font-weight: bold;
}
.modal-title.grey.sub-heading:focus {
  outline: none;
}
@media only screen and (max-width: 420px) {
  .modal-title.grey.sub-heading {
    padding: 15px;
    margin-top: 30px;
  }
}
.modal-body .form-group {
  min-height: 65px !important;
}
.modal-body .cancel-activation {
  margin: 10px 40px 0px 0px !important;
  float: none !important;
}
@media only screen and (max-width: 420px) {
  .modal-body .cancel-activation {
    width: 220px !important;
    padding-right: 20px !important;
    text-align: justify;
  }
}
.modal-body .button-as-link {
  padding-top: 32px !important;
  margin-right: 120px;
  text-decoration: underline !important;
}
@media only screen and (max-width: 420px) {
  .modal-body .button-as-link {
    padding-top: 10px !important;
    margin-right: 0px;
  }
}
.modal-body p {
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.modal-body .negative-msg {
  padding: 0px 20px 0px 20px !important;
}
.btn-input-control-service {
  position: relative;
  float: left;
  width: 50%;
  min-height: 1px;
}
@media only screen and (max-width: 420px) {
  .btn-input-control-service {
    width: 100%;
  }
}
.button-as-link {
  text-decoration: underline;
}
@media only screen and (max-width: 420px) {
  .ReactModal__Content .modal-body {
    padding-right: 20px !important;
    margin-top: 20px;
  }
}
