.esim-wrapper {
  display: inline !important;
  z-index: 1;
}
.esim-wrapper .esim-title,
.esim-wrapper .esim-subtitle {
  margin-left: 50px;
}
.esim-wrapper .esim-title {
  padding-bottom: 0px !important;
}
.esim-wrapper .esim-wrapper-left {
  padding-left: 40px !important;
}
.esim-wrapper .telstra-banner-logo {
  position: absolute;
  width: 25px;
  height: 50px;
  margin-top: 5px;
}
.esim-wrapper .telstra-banner-logo .img-responsive {
  border: none;
}
.esim-wrapper .margin-top {
  margin-top: 123px;
}
.panel-heading.theme,
h1.theme {
  color: #333333 !important;
  padding-bottom: 0px !important;
}
.panel-heading.theme:focus {
  outline: none;
}
.esim-username {
  margin: 0px 0px 0px 50px;
  padding-bottom: 0px !important ;
  font-weight: bold;
  font-size: 1.2em;
  color: #333333;
  font-family: "Akkurat-light", "Helvetica Neue", "Arial Narrow", Helvetica, Arial, sans-serif;
}
.img-responsive {
  border: none;
}
.bannerImg {
  margin-top: 0px;
  padding-left: 10px;
}
