div[aria-label="Debugger Modal"] {
  top: 100px !important;
}
div[aria-label="Debugger Modal"] .form-element {
  display: inline-block;
}
div[aria-label="Debugger Modal"] .primary {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 20px;
}
div[aria-label="Debugger Modal"] .close {
  margin-right: 15px;
}
