.container {
  opacity: 1;
}
.btn.primary {
  background-color: #0064D2 !important;
  font-family: "Helvetica Neue", Arial, sans-serif !important;
}
.btn.primary:before,
.btn.primary:after {
  background-color: #0064D2 !important;
}
.btn.primary:hover {
  background-color: #00aaf3 !important;
}
.btn.primary:hover::before,
.btn.primary:hover::after {
  background-color: #00aaf3 !important;
}
.btn.primary:focus {
  background-color: #00aaf3 !important;
  outline: 2px solid #004d9d;
  padding-right: 5px;
}
.btn.primary:focus::after {
  background-color: #00aaf3 !important;
  outline: 2px solid #004d9d;
}
