.text-nav dt {
  padding-bottom: 10px;
}
.text-nav dd:before {
  font-family: td-icons;
  display: inline-block;
  content: "\e6e2";
  position: absolute;
}
.text-nav dd a {
  padding-left: 15px;
}
.guide-links {
  margin-top: 40px;
}
.useful-info {
  margin-top: 50px;
}
.useful-info h3 {
  margin-bottom: 20px;
}
.useful-info .box {
  margin-left: -15px;
  margin-right: -15px;
  padding: 0 1px 0 1px;
  margin-bottom: 20px;
}
.useful-info .box .content {
  padding: 0 15px;
}
.useful-info .box .title {
  font-size: 2rem;
}
.useful-info .box .para {
  min-height: 80px;
}
@media only screen and (max-width: 767px) {
  .useful-info .box .para {
    min-height: auto !important;
  }
}
.useful-info .box .link {
  display: block;
  margin-top: 20px;
  text-decoration: none;
}
.useful-info .box .link .arrow-right {
  vertical-align: baseline;
}
.useful-info .box .icon {
  text-align: center;
  background-color: #E1EEF8;
  padding: 9px 0;
}
.useful-info .box .icon i {
  font-size: 32px;
}
.activaton-progress-wrapper {
  margin-top: 30px;
}
.activaton-progress-wrapper .activation-progress {
  margin-top: 30px;
}
.activaton-progress-wrapper .activation-progress .icon {
  text-align: center;
  margin-bottom: 10px;
}
@media only screen and (max-width: 420px) {
  .activaton-progress-wrapper .activation-progress .icon {
    text-align: justify;
  }
}
.activaton-progress-wrapper .activation-progress .title {
  font-size: 1.6rem;
  line-height: 20px;
}
@media only screen and (max-width: 420px) {
  .activaton-progress-wrapper .activation-progress .title {
    margin-top: -35px;
    margin-left: 50px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 420px) {
  .activaton-progress-wrapper .activation-progress .center {
    text-align: justify;
  }
}
@media only screen and (max-width: 420px) {
  .activaton-progress-wrapper .activation-progress {
    margin-top: 10px;
    width: 100%;
    padding-left: 0px;
  }
}
.my-telstra-image {
  float: left;
  height: 54px;
  width: 54px;
  margin-top: 16px;
}
.tick-header {
  padding-left: 18px;
  padding-bottom: 15px;
  padding-top: 5rem;
  width: 67px;
}
.sim-profile {
  width: 56px;
  padding-top: 30px;
}
.my-t-app {
  float: right;
  padding-top: 1.5rem;
}
.confirm-block .confirm-block-header {
  padding-bottom: 1rem;
}
.confirm-block .main-heading {
  padding-top: 1rem;
  padding-left: 2rem;
}
.confirm-block .able-boost-icon {
  padding-right: 0.2rem;
}
.confirm-block .offer-card-details {
  border-radius: 3px;
  margin-left: 0rem !important;
  padding-left: 1rem;
}
.confirm-block .download-card {
  box-sizing: border-box;
  margin-right: 1rem;
  width: 100%;
  margin-top: 2rem;
  margin-left: 1rem;
  border: 1px solid #D2D2D2;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding-left: 0rem;
  padding-right: 0rem;
  background-color: var(--white);
}
.download-content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: var(--white);
}
@media only screen and (max-width: 280px) {
  .download-content {
    width: 206px;
  }
}
@media only screen and (min-width: 281px) and (max-width: 319px) {
  .download-content {
    width: 290px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 345px) {
  .download-content {
    width: 250px;
  }
}
@media only screen and (min-width: 346px) and (max-width: 410px) {
  .download-content {
    width: 290px;
  }
}
@media only screen and (min-width: 411px) and (max-width: 539px) {
  .download-content {
    width: 350px;
  }
}
@media only screen and (min-width: 540px) and (max-width: 720px) {
  .download-content {
    width: 385px;
  }
}
@media only screen and (max-width: 280px) {
  .download-myboost {
    width: 100px;
  }
}
.confirm-block .small-font {
  font-size: 14px;
  padding-left: 1.3rem;
  margin-right: 1rem;
}
.confirm-block .max-limit-reached-alert {
  margin-bottom: 45px;
}
.mob-button {
  margin-bottom: 2.5rem;
  width: 100% !important;
}
.download-card .md {
  display: none;
}
.heading {
  height: 56px;
  line-height: 28px !important;
  width: 204px;
  padding-top: 13px;
  margin-left: 68px;
  margin-bottom: 31px;
}
@media only screen and (max-width: 280px) {
  .heading {
    width: 90%;
    padding-top: 13px;
    margin-left: 68px;
    margin-bottom: 115px;
  }
}
@media only screen and (min-width: 281px) and (max-width: 320px) {
  .heading {
    width: 80%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .heading {
    width: 70%;
  }
}
.boost-image {
  width: 340px;
  float: left;
  margin-left: -23px;
}
@media only screen and (max-width: 280px) {
  .boost-image {
    width: 215px;
  }
}
@media only screen and (min-width: 281px) and (max-width: 319px) {
  .boost-image {
    width: 289px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 345px) {
  .boost-image {
    width: 255px;
    padding-top: 12%;
  }
}
@media only screen and (min-width: 346px) and (max-width: 359px) {
  .boost-image {
    width: 289px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .boost-image {
    width: 295px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 410px) {
  .boost-image {
    width: 310px;
  }
}
@media only screen and (min-width: 411px) and (max-width: 539px) {
  .boost-image {
    width: 349px;
  }
}
@media only screen and (min-width: 540px) and (max-width: 720px) {
  .boost-image {
    width: 390px;
  }
}
@media only screen and (min-width: 728px) and (max-width: 767px) {
  .boost-image {
    width: 422px;
    padding-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .boost-image {
    padding-top: 55%;
    width: 197px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .boost-image {
    padding-top: 15%;
    width: 279px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1100px) {
  .boost-image {
    padding-top: 15%;
    width: 277px;
  }
}
.manage-text-div {
  padding-top: 210px;
  margin-bottom: 25px;
}
@media only screen and (max-width: 280px) {
  .manage-text-div {
    padding-top: 145px;
  }
}
@media only screen and (min-width: 281px) and (max-width: 410px) {
  .manage-text-div {
    padding-top: 180px;
  }
}
@media only screen and (min-width: 411px) and (max-width: 539px) {
  .manage-text-div {
    padding-top: 215px;
  }
}
@media only screen and (min-width: 539px) and (max-width: 720px) {
  .manage-text-div {
    padding-top: 233px;
  }
}
@media only screen and (max-width: 280px) {
  .download-list-items {
    width: 206px;
  }
}
span.custom-tick svg path {
  fill: #FF2400 !important;
}
.downlad-app-btn {
  margin-top: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .downlad-app-btn {
    float: left;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 62rem) {
  .confirm-block .confirm-block-header {
    padding-bottom: 1rem;
  }
  .tick-header {
    margin-left: 4rem;
    width: 84px;
    padding-bottom: 0px;
    padding-top: 5rem;
  }
  .confirm-block .mb-large {
    margin-bottom: 5rem;
  }
  .confirm-block .download-card {
    margin-right: 1rem;
    width: 100%;
    height: 85%;
    border: 1px solid #D2D2D2;
    border-radius: 3px;
    margin-left: 5rem;
    margin-top: 0px;
  }
  .desk-button {
    margin-bottom: 30px;
  }
  .download-card .sm {
    display: none;
  }
  .download-card .md {
    display: block;
  }
  .confirm-block .max-limit-info-bar {
    width: 80%;
  }
}
@media only screen and (min-width: 1101px) and (max-width: 1199px) {
  .boost-image {
    width: 279px;
  }
  .manage-text-div {
    padding-top: 180px;
  }
}
@media only screen and (min-width: 721px) and (max-width: 727px) {
  .boost-image {
    width: 423px;
    padding-bottom: 15px;
  }
}
@media only screen and (min-width: 728px) and (max-width: 727px) {
  .boost-image {
    width: 423px;
    padding-bottom: 15px;
  }
}
#directory-listing [class*=able-MessageSection] {
  max-width: 100%;
  margin-bottom: 40px;
}
#auto-recharge-fail [class*=able-MessageSection] {
  max-width: 100%;
  margin-top: 40px;
}
.confirm-block .esim-profile-error-guide .error-guide-section {
  max-width: 360px;
}
@media only screen and (min-width: 799px) {
  .confirm-block .esim-profile-error-guide .error-guide-section--right {
    margin-left: auto;
    margin-right: auto;
  }
}
.confirm-block .esim-profile-error-guide .esim-error-step-image img {
  height: auto;
  width: 100%;
}
