.confirm-ar-modal header [class$=__icon] {
  background-color: #0064d224;
  margin-bottom: 1rem;
}
.confirm-ar-modal__footer > div:first-of-type {
  margin-bottom: 1rem;
}
@media screen and (min-width: 48rem) {
  .confirm-ar-modal__footer {
    display: flex;
    justify-content: flex-end;
  }
  .confirm-ar-modal__footer > div:first-of-type {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
.confirm-ar-modal__footer div {
  width: auto !important;
}
.confirm-ar-modal__footer .spinning-btn-spinner .loader {
  left: 0 !important;
}
