#globalMessageSection.infobanner-recharge.row,
#globalMessageSection.infobanner-payments.row {
  margin-left: 0;
  margin-right: 0;
}
#globalMessageSection.infobanner-recharge.row .col-lg-5,
#globalMessageSection.infobanner-payments.row .col-lg-5 {
  padding: 0;
}
#globalMessageSection .messageSection {
  max-width: 100%;
}
#globalMessageSection .messageSection.able-MessageSection--Attention,
#globalMessageSection .messageSection.able-MessageSection--Attention--Interactive,
#globalMessageSection .messageSection.able-MessageSection--Information,
#globalMessageSection .messageSection.able-MessageSection--Information--Interactive {
  border-left-color: #FF4300;
}
#globalMessageSection .messageSection.able-MessageSection--Attention > svg use,
#globalMessageSection .messageSection.able-MessageSection--Attention--Interactive > svg use,
#globalMessageSection .messageSection.able-MessageSection--Information > svg use,
#globalMessageSection .messageSection.able-MessageSection--Information--Interactive > svg use {
  fill: #FF4300;
}
#globalMessageSection .messageSection a {
  color: #FF4300;
}
#globalMessageSection .messageSection a svg :is(path, use) {
  fill: #FF4300;
}
#globalMessageSection.infobanner-recharge,
#globalMessageSection.infobanner-payments,
#globalMessageSection.infobanner-review,
#globalMessageSection.infobanner-success {
  margin-bottom: 20px;
}
#globalMessageSection.infobanner-contactdetails {
  margin-bottom: 40px;
}
