@media only screen and (max-width: 767px) {
  .edit-field-modal-content .edit-field-modal-header {
    height: 3rem !important;
    margin-top: 2rem;
  }
  .edit-field-modal-content .edit-field-modal-header .mt-button--icon {
    display: block;
  }
}
.edit-field-modal-content .edit-dob.able-TextField svg {
  position: relative;
  top: auto;
}
.edit-field-modal-content .edit-dob.able-TextField input:focus,
.edit-field-modal-content .edit-dob.able-TextField input:active {
  border: 0 !important;
}
.edit-field-modal-content .edit-dob.able-TextField .has-error {
  border-bottom: 0 !important;
}
.edit-field-modal-content .edit-dob.able-TextField .error-msg {
  padding: 0.5rem 0;
}
.edit-field-modal-content .edit-modal-footer {
  margin-bottom: 4rem;
}
.edit-field-modal-content .edit-modal-footer button {
  width: 100%;
}
