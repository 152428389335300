.Paypal-form {
  width: 100%;
  padding-bottom: 20%;
  padding-top: 20px;
}
.Paypal-body {
  width: 100%;
  margin-top: 23px;
  margin-bottom: 40px;
}
.Paypal-footer {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  display: inline-block;
}
.Paypal-error {
  width: 100%;
  margin-bottom: 32px;
  margin-top: 15px;
}
.paypal-footer-icon {
  width: 20px;
  height: 20px;
}
.footer-icon-span {
  float: left;
}
.footer-text {
  padding-left: 10%;
}
.Error-message {
  display: inline;
  width: 100%  !important;
}
.Error-message-body {
  width: 100%  !important;
  color: black !important;
  margin-left: 6.5% !important;
}
.Error-message-head {
  width: 100%  !important;
  color: black !important;
  margin-left: 1% !important;
}
.footer-ref {
  color: #FF4300 !important;
}
@media only screen and (max-width: 767px) {
  .Paypal-form {
    width: 100%;
  }
  .Paypal-body {
    width: 100%;
    margin-top: 23px;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 62rem) {
  .Paypal-form {
    padding-bottom: 20% ;
  }
}
