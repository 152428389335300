@media only screen and (max-width: 767px) {
  .update-field-modal-content .update-field-modal-header {
    height: 3rem !important;
  }
  .update-field-modal-content .update-field-modal-header .mt-button--icon {
    display: block;
  }
}
.update-field-modal-content .update-modal-footer button {
  width: 100%;
}
