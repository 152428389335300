.telstra-date .date-dropdown {
  margin-left: -5px;
  margin-right: -5px;
}
.telstra-date .date-dropdown .date-input-control {
  position: relative;
  float: left;
  width: 33.33333333%;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}
.telstra-date .date-dropdown .date-input-control .icon-ui-down-arrow-thick {
  top: -28px !important;
}
.telstra-date .date-dropdown .date-input-control .td-icon {
  pointer-events: none;
  top: -30px;
  float: right;
  left: -10px !important;
}
.telstra-date .date-dropdown .date-input-control.hide {
  display: none !important;
}
.telstra-date .date-dropdown .date-input-control select {
  height: 48px !important;
}
.telstra-date .date-dropdown .date-input-control .form-element {
  min-height: 45px !important;
}
.telstra-date .date-dropdown .date-input-control .form-element .text-grey-dark {
  margin-top: 0px !important;
}
.telstra-date .date-dropdown .date-input-control .form-element input {
  margin-top: -10px !important;
  margin-bottom: 0px !important;
}
@media only screen and (max-width: 420px) {
  .telstra-date .date-dropdown .date-input-control {
    width: 100%;
  }
}
.telstra-date .date-dropdown .date-input {
  clear: none !important;
  min-width: 0 !important;
  width: 100%;
  margin-bottom: 0 !important;
}
.telstra-date .error-msg {
  padding-top: 50px;
  margin-bottom: 0px !important;
}
.telstra-date .medicare-g-date-error {
  float: left;
  padding: 0 0 4px 0;
}
