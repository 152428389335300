.div-image {
  display: none;
}
div.card-container {
  margin-top: 24px;
}
.contact-details-side-bar {
  box-sizing: border-box;
  border-left: 4px solid #0eaa28;
  margin-left: 1rem;
  margin-right: 4rem;
  margin-bottom: 2rem;
  margin-top: -1rem;
}
@media only screen and (max-width: 420px) {
  .contact-details-side-bar {
    margin-left: 1rem;
    margin-right: 2rem;
  }
}
.contact-details-tick-svg {
  padding-right: 11px;
}
.container.ppa-recharge-block {
  padding-right: 27px;
  padding-left: 27px;
  margin-bottom: 68px;
}
.recharge-card {
  box-sizing: border-box;
  background-color: var(--white) !important;
  border: 1px solid #D2D2D2;
  border-radius: 3px;
}
.recharge-card__top {
  padding: 16px;
  padding-top: 30px;
}
.recharge-card__top .notify-badge-bonus {
  min-height: 20px;
  height: unset;
}
.recharge-card__top .info-and-price {
  flex-direction: row-reverse;
  width: 100%;
}
.recharge-card__top .info-and-price .planheading {
  flex: 1;
}
.recharge-card__top .info-and-price .price-ftdiscount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.25rem;
}
.recharge-card__top .info-and-price .price-ftdiscount:has(div+div) {
  margin-bottom: 0;
}
.row.no-gutters {
  margin: 0;
}
.right-align {
  position: absolute;
  right: 20px;
}
.padding-top-s {
  padding-top: 8px;
}
.plan-condition {
  padding-top: 16px;
}
.card-select {
  margin-top: 24px;
}
.inclusion-item {
  color: #414141;
  font-family: Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 21px;
  padding-left: 8px;
  padding-top: 5px;
}
.inclusion-item .able-InlineLink a:hover,
.inclusion-item .able-InlineLink a:focus {
  background-color: rgba(255, 231, 204, 0.3) !important;
}
.recharge-later {
  margin-top: 31px ;
}
.phone-illustration-image {
  background: url('../../assets/img/svg/Boost-Recharge_Image.jpg');
  width: 359px;
  height: 375px;
}
.value-sim-label1 {
  margin-top: 12px;
}
.value-sim-label2 {
  margin-bottom: 12px;
  margin-top: 16px;
}
@media only screen and (min-width: 48rem) {
  div.card-container:nth-child(even) {
    margin-left: 38px;
  }
  .recharge-card-container.card-container:nth-child(even) {
    margin-left: 38px;
  }
  div.card-container {
    margin-top: 32px;
    margin-left: 0;
  }
  .container.ppa-recharge-block {
    margin-bottom: 92px;
    padding-left: 6rem;
  }
  .ppa-recharge-block .mt-alert {
    width: 42%;
  }
}
.ppa-recharge-block .custom-spinner {
  height: 400px;
  position: relative;
}
.custom-tick g {
  fill: transparent;
}
.custom-tick g path {
  fill: transparent;
}
.custom-tick g path:last-of-type {
  fill: #FF4300;
}
.notify-badge-bonus {
  position: absolute;
  left: 20px;
  top: -12px;
  text-align: center;
  height: 20px;
  max-width: calc(100% - 10px);
  color: #f8f8f8 !important;
  font-family: Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.05px;
  line-height: 20px;
  border-radius: 9.5px;
  background-color: #FF4300;
  padding: 0 8px;
}
div.bonus-border {
  border-top: 4px solid #FF4300;
  border-radius: 3px;
}
.recharge-card-container {
  margin-top: 24px;
}
.recharge-card-container .able-Accordion--compact h2 > button {
  border-radius: 0 !important;
}
.recharge-card-container .able-Accordion--compact h2 > button:focus-visible::before,
.recharge-card-container .able-Accordion--compact h2 > button:active::before,
.recharge-card-container .able-Accordion--compact h2 > button:hover::before {
  border-radius: 0 !important;
}
.recharge-card-container.bonus-border {
  border-top: 4px solid #FF4300;
  position: relative;
}
.recharge-card-container.bonus-border legend {
  max-width: 90%;
  margin: 0 auto;
  background-color: #FF4300;
  color: #f8f8f8 !important;
  border-radius: 16px;
  text-align: center;
  padding: 4px 8px;
  position: absolute;
  left: 1px;
  top: -2px;
  transform: translate(5%, -50%);
}
.bonus-border .recharge-card {
  border-top: none;
}
.inclusion-container {
  display: flex;
  margin-top: 12px;
}
div.inclusion-container:first-child {
  margin-top: 0;
}
@media only screen and (max-width: 767px) {
  .skip-recharge-btns {
    width: 80% !important;
  }
}
.skip-recharge-btn-container {
  display: flex;
  justify-content: right;
  margin-bottom: 6%;
  margin-right: 5%;
  margin-top: 2%;
}
@media only screen and (max-width: 767px) {
  .skip-recharge-btn-container {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.add-rech-btn {
  margin-right: 5%;
}
@media only screen and (max-width: 767px) {
  .add-rech-btn {
    margin-right: 0%;
    margin-bottom: 5%;
  }
}
.skip-recharge-content-container {
  margin-left: 32px;
  margin-top: 31px;
}
.skip-recharge-icon {
  width: 60px;
  height: 80px;
  margin-bottom: 16px;
}
.skip-recharge-ul {
  padding-inline-start: 15px !important;
}
.ppa-recharge-block [class*=able-MessageSection] {
  min-width: 100%;
}
.ppa-recharge-block [class*=able-MessageSection] button:is(:hover, :focus, :active) {
  color: #DB2A00;
}
@media screen and (min-width: 62rem) {
  #recharge-options-tabs div[role="tablist"] {
    width: 42%;
  }
}
.ppa-recharge-block .ppa-block-header {
  margin-bottom: 56px;
}
@media only screen and (max-width: 420px) {
  .ppa-recharge-block .ppa-block-header {
    margin-bottom: 39px !important;
  }
}
.inclusions .zone-list {
  display: flex;
  flex-wrap: wrap;
}
.inclusions .zone-col {
  flex: 1;
  margin: 0 5px;
}
.inclusions .zone-label {
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.close-button {
  padding-bottom: 2rem !important;
  margin-left: 1rem;
  margin-bottom: 3rem;
  width: 90% !important;
  margin-top: 1rem;
}
@media only screen and (min-width: 768px) and (max-width: 2048px) {
  .close-button {
    margin-left: 25rem !important;
    width: 30% !important;
  }
}
.zone .ppv-header {
  height: auto !important;
}
.zone .modal-content {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .zone .modal-content {
    overflow: auto !important;
    max-height: 550px !important;
    width: 121% !important;
  }
}
.zone .able-ActionButton--high-emphasis {
  background-color: #FF4300 !important;
  color: #282828 !important;
}
.zone .able-text-style--FinePrintA {
  margin-bottom: 1rem;
}
.recharge-card .able-InlineLink a:hover,
.recharge-card .able-InlineLink a:focus {
  background-color: rgba(255, 231, 204, 0.3) !important;
  text-shadow: 0.0625rem 0.125rem 0 rgba(255, 231, 204, 0.3), 0 0.125rem 0 rgba(255, 231, 204, 0.3), -0.0625rem 0.125rem 0 rgba(255, 231, 204, 0.3), 0 -0.0625rem 0 rgba(255, 231, 204, 0.3) !important;
}
.price-rise-modal #ModalContent-heading {
  padding-bottom: 24px;
}
.price-rise-modal .d-flex {
  gap: 0.625rem;
}
.price-rise-modal .SpinningButton-container {
  flex-basis: 50%;
  margin-top: 1.5rem;
}
@media screen and (min-width: 48rem) {
  .price-rise-modal .SpinningButton-container .price-rise-continueBtn {
    width: auto;
    min-width: 11.25rem;
    float: right;
  }
}
@media screen and (max-width: 63.996rem) {
  .price-rise-modal .SpinningButton-container .price-rise-continueBtn {
    width: 100%;
  }
}
