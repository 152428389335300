.credit-card-form {
  padding-top: 5%;
  padding-bottom: 2%;
}
.CreditCardPayment-hosted-field {
  height: 45px;
  margin-bottom: 1em;
  display: block;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative;
}
.CreditCardPayment-hosted-field.customselect {
  background: url('../../../assets/img/svg/chevron.svg') no-repeat right;
  background-position-x: 98%;
}
.CreditCardPayment-hosted-field.is-invalid {
  border-bottom: 1px solid red;
  transition: all 200ms ease;
}
.CreditCardPayment-invalid-message {
  visibility: hidden;
  display: none;
}
.CreditCardPayment-invalid-message.is-invalid {
  visibility: visible;
  color: red;
  padding-bottom: 10%;
  display: block;
}
.CreditCard-expiry-box {
  display: flow-root;
}
.Error-message {
  display: inline;
  width: 100%  !important;
}
.Error-message-body {
  width: 100%  !important;
  color: black !important;
  margin-left: 6.5% !important;
}
.Error-message-head {
  width: 100%  !important;
  color: black !important;
  margin-left: 1% !important;
}
.Billpay-error {
  width: 100%;
  margin-bottom: 5%;
}
.braintree-hosted-fields-focused {
  border-bottom: 2px solid #FF4300;
  transition: all 200ms ease;
}
.braintree-hosted-fields-focused.is-invalid {
  border-bottom: 2px solid #EB001B;
  box-shadow: none;
  transition: all 200ms ease;
  outline: 0;
}
.braintree-hosted-fields-invalid {
  border-bottom: 1px solid #EB001B;
  transition: all 200ms ease;
}
#CreditCardPayment-card-image-mc,
#CreditCardPayment-card-image-visa {
  position: absolute;
}
#CreditCardPayment-card-image-mc.master-card {
  top: 2.5em;
  right: 2.5em;
}
#CreditCardPayment-card-image-visa.visa {
  top: 2.5em;
  right: 0em;
}
#CreditCardPayment-card-image-display {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 30px;
  height: 23px;
  border: 1px solid lightgray;
}
.credit-card-icons-enable {
  opacity: 1;
}
.credit-card-icons-disable {
  opacity: 0.1;
}
.CreditCardPayment-expiry-cardinfo {
  position: relative;
}
.CreditCardPayment-expiry {
  width: 100%;
}
.CreditCardPayment-expiry-month {
  width: 48%;
  float: left;
}
.CreditCardPayment-expiry-year {
  width: 48%;
  float: right;
}
.CreditCardPayment-expiry-month-year {
  width: 100%;
}
.expiry-month-year {
  display: inline-flex;
}
.CreditCardPayment-cvv {
  width: 38%;
}
.CreditCardPayment-braintree {
  width: 100%;
  margin-top: 32px;
}
.CreditCardPayment-submit-button {
  width: 100% !important;
  margin-top: 32px;
}
hr.credit-card-submit-margin .savedCreditCardCheckbox,
.savedCreditCardCheckboxContainer .savedCreditCardCheckbox {
  height: 44px;
}
hr.credit-card-submit-margin hr,
.savedCreditCardCheckboxContainer hr {
  border: 0;
}
.CreditCardPayment-expiry label {
  display: inline;
}
.payment-lock {
  width: 80%;
}
@media only screen and (max-width: 767px) {
  .CreditCardPayment-braintree {
    width: 100%;
  }
  .CreditCardPayment-expiry {
    width: 100%;
  }
  .CreditCardPayment-cvv {
    width: 100%;
  }
  .CreditCardPayment-submit-button {
    width: 100% !important;
  }
  .payment-lock {
    width: 100%;
  }
}
.custom-lock {
  margin-right: 18px;
  height: 20px;
}
.boost-privacy-statement {
  color: #FF4300 !important;
}
.credit-card-form .checkmark {
  top: 24px;
}
select#expiration-month {
  height: 44px !important;
}
select {
  min-height: 44px !important;
}
.saved-card-details {
  background-color: var(--white);
}
#cvv-error:not(.is-invalid) svg {
  display: none;
}
