.review-details .offer-card-details {
  margin-left: 0rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.review-details .card-body {
  width: 100% !important;
}
.review-details .small-font {
  font-size: 14px;
  padding-left: 1.3rem;
  margin-right: 1rem;
}
.review-details .button {
  width: 100%;
  margin-top: 2rem;
}
.review-details .custom-tick {
  fill: transparent;
  padding-right: 0.5rem;
  color: black;
}
.review-details .boost-body-text {
  padding-top: 5% !important;
}
.review-details .activate-button {
  width: 100% !important;
}
.review-details .submit-button {
  color: #FF4300 !important;
}
.review-details .header {
  margin-bottom: 32px;
}
.review-details .md {
  display: none;
}
.review-details .alert-card {
  width: 100%;
  color: #FF4300 !important;
  border-bottom: 2px solid #D8D8D8;
}
.review-details .mt-alert:after {
  background: #FF4300 !important;
}
.review-details .recharge-button {
  left: 50%;
}
/*--------Desktop --------------*/
@media only screen and (min-width: 62rem) {
  .review-details .sm {
    display: none;
  }
  .review-details .alert-card {
    width: 100%;
    color: #FF4300 !important;
    border-bottom: 2px solid #D8D8D8;
  }
  .review-details .md {
    display: block;
  }
  .review-details .boost-body-text {
    padding-top: 0% !important;
  }
  .review-details .recharge-button {
    left: 70%;
  }
  .review-details .offer-card-details {
    margin-left: 150px !important;
  }
}
@media only screen and (min-width: 767px) {
  .review-details .recharge-button {
    left: 60%;
  }
}
.custom-list {
  list-style: none;
  padding: 0px;
}
