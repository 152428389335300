.icon-alert-solid-round {
  color: #bb2914 !important;
  float: left !important;
  top: 0px !important;
  left: 0px !important;
  font-size: 18px !important;
  padding-right: 5px;
}
.custom-icon {
  padding-left: 0px !important;
  padding-right: 5px;
  height: 24px;
  width: 24px;
}
