.esim-wrapper .react-type-ahead {
  margin: 30px 0px 50px 0px !important;
}
.ppa-autocomplete {
  color: #414141;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.form-element select option {
  text-align: left;
  height: 24px;
  font-size: 14px;
  line-height: 20px;
}
.form-element .react-type-ahead .error-msg {
  margin-top: 10px;
}
.form-element .react-type-ahead div.Select-placeholder {
  padding: 0px 0px !important;
  padding-top: 15px;
  margin-top: 13px;
  color: #333333 !important;
  color: #414141;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.form-element .react-type-ahead .ppa-autocomplete {
  height: 48px;
}
.form-element .react-type-ahead .ppa-autocomplete .has-error:not(.is-focused) {
  border-style: none !important;
}
.form-element .react-type-ahead .ppa-autocomplete .has-error:not(.is-focused) .Select-control {
  border-color: #B32034 !important;
  background-color: #f8f8f8 !important;
}
.form-element .react-type-ahead .Select.is-focused:not(.is-open) {
  outline: none !important;
}
.form-element .react-type-ahead .Select.is-focused:not(.is-open) .Select-control {
  border-color: #f4f4f4 !important;
  box-shadow: none;
}
.select-input {
  display: flex;
}
.select-input .select-input-spinner {
  position: absolute;
  right: 0px;
}
.select-input .mt-spinner--mini {
  height: 25px;
  position: absolute;
  right: 0;
}
.status {
  line-height: 52px;
}
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  padding: 0px 0px 10px !important;
}
.react-autosuggest__input--focused {
  border-bottom: 2px solid #FF4300 !important;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  border-radius: 0px;
  background-color: var(--white);
  color: #414141;
  font-family: Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 24px;
  z-index: 2;
  min-width: 230px;
  border: 1px solid #B4AAAA;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: scroll;
  height: 200px;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #FFC999 !important;
  font-weight: bold;
}
.search-icon {
  position: absolute;
  right: 1px;
}
