.telstra-footer {
  flex-shrink: 0;
  width: 100%;
  background-color: #282828;
  box-sizing: border-box;
  position: fixed;
  bottom: 0px;
  height: 60px;
  z-index: 100;
}
.footer-container {
  padding: 24px 24px;
  display: flex;
  justify-content: center;
  color: #f8f8f8 !important;
}
.footer-container a {
  color: #f8f8f8 !important;
  padding: 14px 14px;
  text-decoration: none;
}
.footer-container a:focus {
  text-decoration: underline;
  background-color: #282828;
  box-shadow: 0 0 0 3px #FF4300 !important;
}
.footer-container a:hover {
  text-decoration: underline;
  background-color: #282828;
}
.footer-container a:visited {
  text-decoration: underline !important;
  outline: none !important;
}
.footer-list {
  list-style: none;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 767px) {
  .telstra-footer {
    position: inherit;
    height: 240px;
    margin-top: auto;
    padding: 30px 30px;
  }
  .foot-height,
  .footer-container,
  .footer-list {
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer-container a {
    padding-left: 0px;
    padding-top: 20px;
  }
  .footer-list {
    padding-top: 5px;
  }
}
