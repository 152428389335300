.radio {
  padding-left: 0;
  margin-top: 20px;
}
.radio input {
  width: auto;
}
.radio-button input[type="radio"] {
  opacity: 0 !important;
  position: absolute;
}
.radio-button input[type="radio"]:focus + label::before {
  border: 1px solid #004d9d;
}
@media only screen and (max-width: 767px) {
  .radio-button {
    margin-bottom: 10px;
  }
}
.radio-button {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  color: #666;
}
.radio-button label span {
  pointer-events: none;
}
.radio-button label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 18px;
  height: 18px;
  border: 1px solid #949494;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
}
