@font-face {
  font-family: "Roboto-Bold";
  font-display: swap;
  src: url(assets/fonts/Roboto/roboto-bold.woff);
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Regular";
  font-display: swap;
  src: url(assets/fonts/Roboto/roboto-regular.woff);
  font-weight: 300;
  font-style: normal;
}
.boost-ppa .mt-spinner svg path {
  stroke: #FF4300 !important;
}
.ppv-modal .mt-sheet__toolbar:before {
  background: none !important;
}
.boost-ppa .mt-alert,
.boost-ppa .mt-card {
  background-color: var(--white);
}
.mt-alert--default svg g g {
  stroke: #FF4300 !important;
}
.boost-color-svg path,
.boost-color-svg use,
.boost-color-svg rect {
  fill: #FF4300 !important;
}
