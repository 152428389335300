.row {
  margin: 20px;
  margin-left: 65px;
}
@media only screen and (max-width: 420px) {
  .row {
    margin: 0px;
  }
}
@media only screen and (min-width: 420px) and (max-width: 912px) {
  .row:not(.no-gutters) {
    margin: 20px !important;
  }
}
.row .sub-heading {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .col-sm-6 {
    width: 380px !important;
    height: 300px !important;
    margin: 10px;
  }
}
@media only screen and (max-width: 420px) {
  .col-sm-6 {
    margin-top: 25px;
  }
}
.col-sm-6 .card {
  border: 1px solid #dadada;
  width: 380px;
  height: 300px;
}
@media only screen and (max-width: 420px) {
  .col-sm-6 .card {
    width: 100%;
  }
}
.col-sm-6 .card .card-body {
  padding: 20px;
}
.col-sm-6 .card .card-text {
  margin-top: 20px;
  min-height: 60px;
  padding-right: 45px;
}
.col-sm-6 .card .btn.primary {
  margin-top: 20px !important;
  float: unset !important;
}
@media only screen and (max-width: 420px) {
  .col-sm-6 .card .btn.primary {
    width: 50%;
  }
}
.card-details {
  margin-left: 30px !important;
}
@media only screen and (max-width: 420px) {
  .card-details {
    margin-left: 0px !important;
  }
}
.ppa-service-error strong {
  display: block;
}
