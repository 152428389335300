#remove-middleName-modal {
  border-top: 2px solid #d0021b !important;
  border-image: none !important;
}
#remove-middleName-modal .ppv-header {
  height: 2rem;
}
#remove-middleName-modal .ppv-header .remove-modal-icon {
  position: relative;
  background: rgba(208, 2, 27, 0.12);
  border-radius: 50%;
  padding: 1.2rem;
  height: 2rem;
  width: 2rem;
}
#remove-middleName-modal .ppv-header .remove-modal-icon .able-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: 0;
  margin-right: 0;
}
#remove-middleName-modal .remove-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}
#remove-middleName-modal .remove-modal-footer > :last-child {
  margin-left: 15px;
}
@media only screen and (max-width: 767px) {
  #remove-middleName-modal {
    height: auto !important;
  }
  #remove-middleName-modal .remove-modal-footer .SpinningButton-container {
    width: 100%;
  }
}
