.payment-margin-header {
  margin-top: 38px;
}
.payment-margin-card {
  margin-top: 32px !important;
}
#payment-options-tabs [role="tablist"] button:nth-of-type(2) {
  background: url(../../assets/img/svg/Paypal.svg) no-repeat center !important;
  background-size: 80px 20px !important;
  text-indent: -9999px !important;
}
#payment-options-tabs > div:last-of-type > div {
  margin-top: 0 !important;
}
.payment-card {
  padding: 1rem 20px;
  border-left: 4px solid var(--accentPromotion);
  margin-left: 0rem;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #D2D2D2;
  border-right: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
  background-color: var(--white);
}
.payment-card .offer-inclusions [role="listitem"] .able-icon {
  margin-right: 3px;
  margin-left: 0;
}
.actual-amount {
  float: right;
}
.activate-class {
  width: 100% !important;
  padding-bottom: 20% !important;
}
.activate-button {
  width: 100% !important;
}
@media only screen and (min-width: 62rem) {
  .payment-activate-form {
    padding-right: 10% !important;
  }
  .payment-margin-header {
    margin-top: 32px;
  }
  .activate-class {
    width: 56% !important;
  }
  .activate-button {
    padding-left: 3% ;
    width: 80% !important;
    padding-right: 3% ;
  }
  .activate-button-div {
    margin-left: 8%;
  }
}
@media only screen and (device-width: 768px) {
  .payment-margin-header {
    margin-top: 32px;
  }
  .activate-class {
    width: 56% !important;
  }
  .activate-button {
    width: 100% !important;
  }
}
.bonus-text {
  color: var(--accentPromotion) !important;
}
.promotion-capsule {
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
  border-radius: 9.5px;
  background-color: #FF4300;
  padding: 1px 8px;
  margin-left: 8px;
}
.autorecharge-main {
  box-sizing: border-box;
  border: 1px solid #757575;
  border-radius: 3px;
}
.auto-title {
  margin-top: 50px;
}
.info-and-price {
  flex-direction: row-reverse;
  width: 100%;
}
.info-and-price .totalpay {
  flex: 1;
}
.custom-list {
  list-style: none;
  padding: 0px;
}
