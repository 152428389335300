.edit-contact-details .edit-contact-details-heading {
  margin: 0px 0 !important;
}
.edit-contact-details .edit-contact-details-toast,
.edit-contact-details .edit-contact-details-heading,
.edit-contact-details .edit-contact-details-alert-bar {
  margin: 10px 0;
}
.edit-contact-details .field-error svg {
  margin-left: 0;
}
