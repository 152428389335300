@media only screen and (max-width: 420px) {
  .modal-lg {
    width: 90% !important;
  }
}
@media only screen and (min-width: 430px) and (max-width: 600px) {
  .modal-sm {
    width: 90% !important;
  }
}
#modal-content {
  border-radius: 10px !important ;
  padding: 25px !important;
}
.bannerImg {
  padding-left: 0 !important;
  width: 100%;
}
@media only screen and (min-width: 430px) {
  .modal-close {
    margin-top: 14px;
  }
}
@media only screen and (min-width: 600px) {
  .hide-on-desktop,
  * [aria-labelledby='hide-on-desktop'] {
    display: none;
    max-height: 0;
    overflow: hidden;
  }
}
#modal-body {
  padding: 0px !important;
  margin-top: 15px;
}
.mobile-image-modal-body {
  overflow: auto;
  max-height: 450px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 420px) {
  .modal-message {
    margin-bottom: 14px;
  }
}
.idv-link-text [tabindex]:focus:not(:hover),
.idv-link-text:focus:not(:hover) {
  -webkit-box-shadow: 0 0 0 3px #FF7F3F;
  box-shadow: 0 0 0 3px #FF7F3F;
  text-decoration: underline !important;
}
.disabled-link {
  color: currentColor !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}
