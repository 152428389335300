#learn-more-link {
  color: #DB2A00;
  text-decoration: underline;
}
.learn-more-modal header [class$=__picto] > svg {
  margin-left: 0;
  width: 2rem;
  height: 2rem;
}
.learn-more-modal header [class$=__picto] > svg path,
.learn-more-modal header [class$=__picto] > svg use,
.learn-more-modal header [class$=__picto] > svg rect {
  fill: #FF4300 !important;
}
@media screen and (min-width: 48rem) {
  .learn-more-modal footer {
    display: flex;
  }
}
