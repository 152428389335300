.ppa-progress {
  margin-top: 30px;
}
@media only screen and (max-width: 420px) {
  .ppa-progress {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.step-name {
  height: 20px;
  color: #282828;
  font-family: Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: small;
  letter-spacing: -0.2px;
  line-height: 20px;
}
.step {
  width: 100%;
  position: relative;
}
.hide {
  display: none;
}
.step-wrapp {
  display: flex !important;
}
.step-number-selected {
  border: 1px solid #D8D8D8;
  color: #f8f8f8;
}
.step-number {
  border-radius: 50%;
  border: 2px solid #D2D2D2;
  width: 34px;
  height: 34px;
  font-family: Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 4px;
  text-align: center;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-left: 3px;
  background-color: #f8f8f8;
}
.tick {
  background: url('../../assets/img/svg/Tick.svg');
  width: 95%;
  height: 95%;
  background-size: contain;
  background-repeat: no-repeat;
}
.ppa-progress .progress-info {
  min-height: 20px;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 60px;
  padding-left: 25px;
}
.horizontal-line {
  border-top: 2px solid #D8D8D8;
  margin-top: 15px;
  width: 100%;
  flex: 1 1;
  margin-left: 3px;
}
.dummy-horizontal-line {
  border-top: 0px solid #D8D8D8;
  margin-top: 15px;
  width: 19%;
  margin-left: 3px;
}
[class*=titleMargin] {
  transform: translateX(-25%);
  position: absolute;
}
@media only screen and (min-width: 62rem) {
  .ppa-progress {
    margin-bottom: 30px;
  }
  .step-name {
    display: block;
    font-size: 16px;
    margin-top: 4%;
  }
  .step-number-selected {
    color: #f8f8f8;
  }
  .step-number {
    padding: 4px;
  }
}
.visited {
  border: 0px;
  transform: rotate(-9deg);
}
.current {
  background-color: #FF4300 !important;
  color: #282828 !important;
  border-color: #FF4300;
}
