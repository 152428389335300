.update-email-container {
  padding: 0 50px;
}
.update-email-item {
  margin: 0 0 30px 0;
}
.update-email-item button {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .update-contact-title {
    margin-top: 30px !important;
  }
}
