.add-more-icon {
  background: gainsboro;
  padding: 1px;
  position: absolute;
  cursor: pointer;
}
.esim-wrapper .checkbox-label label {
  font-weight: normal;
}
.form-element .styled-checkbox {
  position: absolute;
  opacity: 0;
}
.form-element .styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  height: 24px;
  padding-bottom: 35px;
}
@media only screen and (max-width: 767px) {
  .form-element .styled-checkbox + label {
    height: 50px;
  }
}
.form-element .styled-checkbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  border: 1px solid #747474;
  position: absolute;
  left: 0;
  top: 2px;
}
.form-element .styled-checkbox:checked + label:before {
  background: var(--borderActive) !important;
  border: 0;
}
.form-element .styled-checkbox:disabled + label {
  cursor: auto;
}
.form-element .styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
  opacity: 0.4;
}
.form-element .styled-checkbox:focus + label::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -4px;
  width: 26px;
  height: 26px;
}
.form-element .styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 11px;
  background: #f8f8f8 !important;
  width: 2px;
  height: 2px;
  border: 0;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
.form-element.checkbox-tab-focus {
  border: 2px solid var(--borderActive) !important;
  height: 100%;
  padding-top: 10px;
  padding-left: 10px;
}
